<template>
<AppPublicLayout>
        <template v-slot:header>
            <AppHeader />
        </template>
        <template v-slot:main>
            <div class="drop-password-page">
                <div class="app-title">Массаж в <span>твоем</span> городе</div>
                <div class="page-title">Восстановление пароля</div>
                <div class="form">
                    <AppRadioButton v-for="radio, index in radioBtns" :key="index" :options="radio" @action="getPerson($event)" />
                    <BaseInput
                        rounded
                        outlined
                        width="320"
                        label="Ваш номер телефона"
                        validation="phone"
                        margin="0 0 30px 0"
                        id="phone"
                        required
                        @sendData="getPhone($event)"
                    />
                    <BaseInput
                        rounded
                        outlined
                        width="320"
                        label="Ваш email"
                        validation="email"
                        margin="10px 0 40px 0"
                        id="email"
                        required
                        @sendData="getEmail($event)"
                    />
                    <AppRegularBtn text="отправить" dark :disabled="isFormValid" @action="dropPassword" />
                    <AppPopupWarning :text="warning.text" v-if="warning.isActive" />
                </div>
            </div>            
        </template>
        <template v-slot:footer>
            <AppFooter />
            <AppNavigationMobile />
        </template>
    </AppPublicLayout>

</template>

<script>
import AppPublicLayout from "@/layouts/user/public/app-public-layout.vue";
import AppHeader from "@/sections/app-header.vue";
import AppFooter from "@/sections/app-footer.vue";
import AppNavigationMobile from "@/sections/app-navigation-mobile.vue";
import AppRegularBtn from "@/components/buttons/app-regular-btn.vue";
import AppRadioButton from "@/components/inputs/app-radio-input.vue";
import AppPopupWarning from "@/components/popups/app-popup-warning.vue";
import dropPasswordAPI from "@/api/drop_password.js";
import { ref, computed, reactive } from "vue";
import { useRouter } from "vue-router";
    export default {
        components: {
            AppRegularBtn,
            AppRadioButton,
            AppPublicLayout,
            AppHeader,
            AppFooter,
            AppNavigationMobile,
            AppPopupWarning
        },
        setup(){
            const router = useRouter();
            const phone = ref(null);
            const email = ref("");
            const person = ref("");
            const radioBtns = [
                {
                        id: "massagist",
                        label: "Я массажист(-ка)",
                        name: "person"
                    },
                    {
                        id: "saloon",
                        label: "Я массажный салон",
                        name: "person"
                    },
                    {
                        id: "client",
                        label: "Я клиент",
                        name: "person"
                    }
            ]
            const getPerson = (data) => person.value = data;
            const getPhone = (data) => phone.value = data;
            const getEmail = (data) => email.value = data;
            const warning = reactive({
                text: "",
                isActive: false
            })
            const dropPassword = async () => {
                await dropPasswordAPI.dropPassword({ phone: phone.value, email: email.value, person: person.value })
                    .then(response => {
                        if(response.data.error){
                            warning.isActive = true;
                            warning.text = response.data.data;
                            setTimeout(() => {
                                warning.isActive = false;
                                warning.text = "";
                            }, 2000)
                        } else {
                            warning.isActive = true;
                            warning.text = response.data.data;
                            setTimeout(() => {
                                router.push('login')
                            }, 2000)
                        }
                    })
                    .catch(err => console.log(err))
            }

            const isFormValid = computed(() => {
                let valid = true;
                if(email.value.length > 0 && phone.value && person.value){
                    valid = false
                } 
                return valid;
            })

            return {
                phone,
                email,
                person,
                radioBtns,
                getPhone,
                getEmail,
                getPerson,
                isFormValid,
                dropPassword,
                warning

            }
        }
        
    }
</script>

<style lang="scss" scoped>
    .drop-password-page{
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        .app-title{
            font-size: 1.5em;
            font-weight: 700;
            color: $black;
            span{
                color: $accent-dark;
            }
        }
        .page-title{
            font-size: 2.2em;
            color: $black;
            font-weight: 700;
            text-transform: uppercase;
            margin: 1em 0;
        }
        .form{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
</style>